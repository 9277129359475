import React from "react"

const IconSkills = () => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 226.772 226.771"
      width="200"
      aria-labelledby="uniqueTitleID uniqueDescID"
      role="img"
    >
      <title id="uniqueTitleID">Skills</title>
      <desc id="uniqueDescID">Skills icon</desc>
      <path className="logo--highlight" d="M113.386 1.418C51.647 1.418 1.417 51.647 1.417 113.386c0 61.74 50.229 111.969 111.969 111.969 61.74 0 111.969-50.229 111.969-111.969 0-61.739-50.229-111.968-111.969-111.968z"/>
      <path className="logo--lowlight" d="M202.601 99.195v2.452c.002 19.775-.147 39.552.076 59.324.089 7.8-6.643 14.712-14.733 14.69-49.637-.133-99.276-.138-148.912.01-8.063.025-15.04-6.664-14.936-14.914.247-19.706.079-39.417.085-59.126 0-.702.061-1.403.106-2.388 3.189 1.488 6.107 2.862 9.036 4.214 15.618 7.205 31.559 13.54 48.343 17.492 4.64 1.093 9.375 1.8 14.086 2.55 1.428.227 2.101.603 2.035 2.202-.124 3.05-.059 6.106-.023 9.158.032 2.91 1.373 4.258 4.295 4.268 7.564.021 15.13.021 22.695-.004 2.933-.006 4.258-1.337 4.289-4.254.031-3.119.057-6.24-.014-9.355-.03-1.23.353-1.802 1.615-1.953 16.344-1.956 31.817-7.085 46.974-13.214 7.421-3.001 14.648-6.484 21.964-9.754.888-.397 1.771-.818 3.019-1.398z"/>
      <path className="logo--primary" d="M129.052 115.578c0-3.004-.131-5.976.035-8.934.166-3.002-1.023-4.626-4.129-4.724-3.846-.123-7.697-.07-11.545-.075-3.784-.005-7.566.022-11.349.017-2.847-.003-4.303 1.437-4.285 4.222.02 3.17.003 6.34.003 9.964-5.323-1.052-10.382-1.776-15.287-3.078-7.154-1.9-14.393-3.728-21.244-6.465-11.523-4.603-22.732-9.981-34.158-14.834-2.493-1.06-3.027-2.489-2.953-4.957.174-5.769.283-11.558 0-17.318-.43-8.745 5.272-15.982 15.764-15.899 8.492.067 16.982-.051 25.471.104 1.794.033 1.978-.604 1.966-2.022-.043-4.646-.034-9.292-.013-13.938.039-8.909 5.961-14.861 14.857-14.87 20.906-.02 41.814-.022 62.722-.003 8.441.007 14.397 5.883 14.542 14.365.084 4.844.056 9.691-.005 14.536-.019 1.328.316 1.882 1.761 1.87 7.696-.059 15.394-.153 23.084.03 3.27.076 6.706.274 9.744 1.341 5.378 1.889 8.243 6.27 8.46 11.89.286 7.355.112 14.732.03 22.098-.005.665-.623 1.652-1.22 1.931-8.105 3.792-16.22 7.574-24.413 11.168-4.804 2.107-9.682 4.098-14.655 5.75-10.315 3.426-20.718 6.578-31.58 7.825-.445.05-.901.006-1.603.006zM113.185 53.51c12.539 0 25.076-.026 37.615.032 1.653.008 2.183-.502 2.148-2.154-.092-4.642-.021-9.287-.037-13.931-.012-5.127-3.046-8.154-8.148-8.153-20.898.005-41.796.01-62.694.019-5.168.001-8.147 2.934-8.185 8.098-.032 4.511.076 9.024-.052 13.532-.056 1.957.457 2.622 2.534 2.604 12.273-.099 24.546-.049 36.819-.047z"/>
      <path className="logo--primary" d="M121.348 106.083h-15.332c-1.565 0-2.829 2.259-2.829 3.825v22.329c0 1.565 1.263 1.847 2.829 1.847h15.332c1.564 0 2.84-.281 2.84-1.847v-22.329c0-1.566-1.275-3.825-2.84-3.825zM192.802 114.359l-1.289.789c-19.903 12.167-32.153 30.792-36.16 37.565l-3.429-7.115-.104-.181c-1.639-2.458-3.721-2.974-5.18-2.974-3.497 0-6.672 2.945-6.806 3.071l-2.03 1.907 2.71.645c5.444 1.296 9.609 14.672 10.755 19.717l.49 2.157 7.991-5.493.159-.477c7.679-23.012 34.134-44.362 34.4-44.575l1.025-.819-2.532-4.217z"/>
    </svg>
  </>
)

export default IconSkills
