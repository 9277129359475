import React from "react"

const IconOnline = () => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 226.772 226.771"
      width="200"
      aria-labelledby="uniqueTitleID uniqueDescID"
      role="img"
    >
      <title id="uniqueTitleID">Online</title>
      <desc id="uniqueDescID">Online icon</desc>
      <path className="logo--highlight" d="M113.386 1.418C51.647 1.418 1.417 51.647 1.417 113.386c0 61.74 50.229 111.969 111.969 111.969 61.74 0 111.969-50.229 111.969-111.969 0-61.739-50.229-111.968-111.969-111.968z"/>
      <g className="logo--primary">
        <path d="M80.707 112.959l6.413 6.817c-2.579 2.47-5.139 4.839-7.607 7.298-10.318 10.281-20.602 20.595-30.918 30.876-1.221 1.217-2.007 2.508-1.256 4.236.968 2.229 3.629 2.579 5.518.703 5.101-5.063 10.171-10.158 15.254-15.24 7.421-7.423 14.841-14.845 22.264-22.265.31-.309.643-.594.788-.727l7.336 6.609c-1.001.712-1.874 1.169-2.541 1.834-11.94 11.899-23.857 23.822-35.774 35.745-.448.447-.914.895-1.274 1.409-.975 1.392-.812 3.009.392 4.143 1.292 1.214 2.724 1.332 4.154.311.716-.513 1.309-1.202 1.938-1.831 11.832-11.829 23.658-23.66 35.489-35.488.484-.484.995-.939 1.628-1.534l6.327 6.626c-.362.434-.646.832-.988 1.174-13.037 13.046-26.072 26.096-39.124 39.125-7.132 7.122-16.899 7.094-24.073-.034-1.85-1.838-3.713-3.665-5.521-5.545-6.385-6.637-6.498-16.427-.025-22.987 11.302-11.456 22.734-22.783 34.136-34.141 2.412-2.404 4.929-4.704 7.464-7.114zM177.718 35.969l8.759 8.7c-3.904 5.357-7.804 10.751-11.773 16.091-.457.614-1.267 1.049-2.002 1.36-5.114 2.167-9.284 5.516-13.14 9.51-8.344 8.641-16.947 17.03-25.433 25.534-.487.49-.865 1.089-1.4 1.771l-9.243-8.843c1.885-1.912 3.723-3.795 5.584-5.656 8.855-8.853 17.765-17.651 26.525-26.597 1.416-1.445 2.361-3.452 3.19-5.345 1.366-3.122 3.39-5.48 6.256-7.377 4.334-2.867 8.447-6.07 12.677-9.148z"/>
      </g>
      <path className="logo--lowlight" d="M178.667 124.372L65.281 43.8l32.417 135.264 20.6-34.374 31.149 46.114 20.79-14.04-31.151-46.115z"/>
      <path className="logo--highlight" d="M149.547 196.642l-31.268-46.291-22.389 37.356L59.423 35.535l127.559 90.644-43.017 6.822 31.272 46.292-25.69 17.349zm-31.527-55.88l32.914 48.728 17.149-11.582-32.915-48.728 38.542-6.112-106.077-75.38L97.96 174.234l20.06-33.472z"/>

    </svg>
  </>
)

export default IconOnline
