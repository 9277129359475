import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import IconIntro from "../components/icon-intro";
import IconBullseye from "../components/icon-bullseye";
import IconOnline from "../components/icon-online";
import IconSkills from "../components/icon-skills";
import IconSoftware from "../components/icon-software";

const IndexPage = () => (
  <Layout>
    <SEO title="Marketing and Designs" />
    <article>
      <section className="section section__splash">
        <h1>Your Brand's Best Friend</h1>
      </section>
      <section className="section section--primary">
        <div className="section__icon">
          <IconIntro />
        </div>
        <div className="section__content">
          <h2>Intro</h2>
          <p>
            I’m an all-round marketing professional with 15+ years’ experience in all aspects of Digital and Offline Marketing, as well as Graphic Design, Event Management and Copywriting.
          </p>
          <p>
            After years of climbing the corporate ladder I was fortunate enough to become a mom &mdash; That’s when I realized that <em>flexibility is key!</em> Establishing Orange Dog has allowed me to set my own hours, select my own clients and ultimately achieve both my family and career goals.
          </p>
        </div>
      </section>

      <section className="section section--secondary">
        <div className="section__icon">
          <IconBullseye />
        </div>
        <div className="section__content">
          <h2>Me to you</h2>
          <p>
            Hiring an in-house marketing manager is an expense that not all small to medium businesses can prioritise. That’s not to say they don’t need it!
          </p>
          <p>
            As your personal Marketing Consultant, I can offer the same flexibility to your business, that I found so necessary. I will be your partner, helping you to take advantage of marketing opportunities, across a variety of platforms.
          </p>
          <p>A retainer agreement ensures forty hours of dedicated marketing work per month as well as a comprehensive start up meeting to determine your marketing requirements and priorities.</p>
          <p>Email me (<a className="email" href="mailto:&#099;&#097;&#114;&#111;&#108;&#121;&#110;&#064;&#111;&#114;&#097;&#110;&#103;&#101;&#100;&#111;&#103;&#046;&#099;&#111;&#046;&#122;&#097;">carolyn@orangedog.co.za</a>) to find out more about included services.</p>
        </div>
      </section>

      <section className="section section--primary">
        <div className="section__icon">
          <IconSkills />
        </div>
        <div className="section__content">
          <h2>Skills</h2>
          <h3>Marketing &amp; Public Relations</h3>
          <ul>
            <li>Creating a Marketing Strategy</li>
            <li>Managing Brand Content</li>
            <li>Corporate Social Responsibility</li>
            <li>Writing Press Releases</li>
            <li>Content Copywriting &amp; Editing</li>
            <li>Advertising &amp; Budget Management</li>
            <li>Design, Plan &amp; Manage Events</li>
          </ul>

          <h3>Graphic Design</h3>
          <ul>
            <li>Design Marketing Collateral (Books / Brochures / Ads / Infographics)</li>
            <li>Print &amp; Online Designs</li>
            <li>Create Bespoke Corporate Identities</li>
            <li>Logo Design &amp; Extensive Rebranding</li>
            <li>Developing a Brand Guide</li>
            <li>Sourcing Branded Items</li>
          </ul>

          <h3>Customer Relationship (CRM)</h3>
          <ul>
            <li>Email Marketing</li>
            <li>SMS &amp; APP Push Notifications</li>
            <li>Customer Retention &amp; Loyalty</li>
            <li>NPS Campaign Setup</li>
            <li>A/B Testing</li>
            <li>Customer Journey Mapping</li>
            <li>Data-Centric Statistics &amp; Reporting</li>
          </ul>

          <h3>Digital &amp; Social Media</h3>
          <ul>
            <li>Web Design &amp; Development (Squarespace / WordPress)</li>
            <li>Shopify Ecommerce Store Setup</li>
            <li>Keyword Research</li>
            <li>Search Engine Optimisation (SEO)</li>
            <li>Integrated Strategies (Facebook / Twitter / Linkedin)</li>
          </ul>
        </div>
      </section>

      <section className="section section--secondary">
        <div className="section__icon">
          <IconSoftware />  
        </div>
        <div className="section__content">
          <h2>Software Knowledge</h2>
          <h3>Adobe Creative Cloud</h3>
          <ul>
            <li>Illustrator</li>
            <li>InDesign</li>
            <li>Photoshop</li>
            <li>Premiere Pro</li>
          </ul>

          <h3>Microsoft Office</h3>
          <ul>
            <li>Access</li>
            <li>Excel</li>
            <li>Office 365 </li>
            <li>Outlook</li>
            <li>PowerPoint</li>
            <li>Publisher</li>
            <li>SharePoint</li>
            <li>Word</li>
          </ul>
        </div>
      </section>
      
      <section className="section section--primary">
        <div className="section__icon">
          <IconOnline />
        </div>
        <div className="section__content">
          <h2>Online Tools &amp; Services</h2>
          <ul>
            <li>Asana</li>
            <li>Facebook &amp; LinkedIn &amp; Twitter</li>
            <li>Google Ads &amp; Analytics</li>
            <li>Mailchimp</li>
            <li>Mixpanel</li>
            <li>Panacea Mobile</li>
            <li>Periscope Data</li>
            <li>Propdata</li>
            <li>Shopify</li>
            <li>Slack</li>
            <li>Squarespace &amp; WordPress</li>
            <li>SurveyMonkey</li>
            <li>Unbounce</li>
            <li>ViralSweep</li>
            <li>YouTube</li>
            <li>3D Issue Flipbooks</li>
          </ul>
        </div>
      </section>
    </article>
  </Layout>
)

export default IndexPage
